<template>
    <div class="knowledgeGraph">
        <back></back>
    </div>
</template>

<script>
import back from './../back';
export default {
    components: {
        back,
    },
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.knowledgeGraph
    height 100%
    overflow hidden
    background url(./../images/knowledgeGraph.png) no-repeat
    background-size 100% 100%
    position relative
</style>